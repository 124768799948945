.sidebar-container {
  max-width: 20%;
  /* Adjust the width as needed */
  background-color: #8B4513;
  /* Optional background color */
  padding: 20px;
  /* Optional padding */
  border-right: 1px solid #7f7676;
  /* Optional border */
  color: rgb(237, 237, 10);
}

.sidebar-heading {
  text-align: center;
  margin-bottom: 1rem;
}

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar-item {
  margin: 1rem 0;
  text-decoration: none;
  color: #f0f0f0;
}

.sidebar-item:hover {
  color: rgb(237, 237, 10);
}

.admin-logout {
  background-color: rgb(211, 208, 208);
  color: #8B4513;
  padding: 2px 6px;
  outline: none;
  border: none;
  border-radius: 10px;
  margin-top: 1rem;
  cursor: pointer;
}