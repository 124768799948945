.banner {
    height: 93vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/images/overlay.png'), url("../../../public/images/copperbanner1.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    animation: backgroundSlide 10s infinite alternate; /* Adjust the duration as needed */
}

@keyframes backgroundSlide {
    0% {
        background-image: url('../../../public/images/overlay.png'), url("../../../public/images/copperbanner1.jpg");
    }
    25% {
        background-image: url('../../../public/images/overlay.png'), url("../../../public/images/copperbanner2.jpg");
    }
    50% {
        background-image: url('../../../public/images/overlay.png'), url("../../../public/images/copperbanner3.jpg");
    }
    75% {
        background-image: url('../../../public/images/overlay.png'), url("../../../public/images/copperbanner4.jpg");
    }
    100% {
        background-image: url('../../../public/images/overlay.png'), url("../../../public/images/copperbanner5.webp");
    }
}


.banner .heading {
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 15px;
}

.banner .quote {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
}

.about-us {
    padding: 2rem 0;
    display: block;
    /* background-color: #ffffff; */
}

.about-us-row {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem 0;
}

.about-us-image {
    width: 30%;
    object-fit: contain;
}

.about-us-image img {
    border-radius: 50%;
    height: 300px;
    width: 300px;
    transition: 0.4s ease-in;
}

.about-us-image img:hover {
    /* transform: rotate(360deg); */
    cursor: pointer;
    /* box-shadow: 0px 0px 20px rgb(146, 145, 145); */
        box-shadow: 0px 0px 20px #8B4513;

}

.about-us-text {
    width: 55%;
    padding-top: 1rem;
    font-size: 1.3rem;
    color: #83491f;
}


.why-us {
    /* margin: 2rem auto; */
    padding: 2rem 0;
    display: block;
    background-color: #ffffff;
    /* background: linear-gradient(to bottom, #FFFFFF, #d38248); */

}

.why-us-row {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem 0;
}

.why-us-row:nth-child(2) {
    flex-direction: row-reverse;
}

.why-us-text {
    padding-top: 1.8rem;
    /* border: 2px solid; */
    width: 60%;
}

.why-us-text h1 {
    margin-bottom: 1rem;
    color: #8B4513;
    font-size: 2rem;
}

.why-us-text p {
    font-size: 1.2rem;
}

.why-us-image img {
    object-fit: contain;
    border-radius: 4px;
}


.testimonials {
    padding: 2rem 0;
    display: block;
    /* background-color: #ffffff; */
}

.card-row {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem 0;
}

.card-container {
    width: 23%;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 18px;
    box-shadow:  0px 1px 20px 10px rgb(220, 220, 220);
    min-height: 350px;
    text-align: left;
    cursor: pointer;
}

.card-container:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    border-radius: 10px;
    width: 0;
    background: linear-gradient(#e2e2e2, #fefdfd);
    color: #000;
    z-index: -1;
    -moz-transition: ease all 0.35s;
    -o-transition: ease all 0.35s;
    -webkit-transition: ease all 0.35s;
    transition: ease all 0.35s;
  }

  .card-container:hover:after {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    right: auto;
  }

.card-heading {
    font-size: 1.5rem;
    color: #8B4513;
    margin-bottom: 1.2rem;
    font-weight: bold;
}

.card-container:hover {
    transform: scale(1.05);
    transition: 0.3s ease-out;
}

.card-content {
    font-size: 17px;
    word-spacing: 1.2px;
}

.golden-text {
    color: rgb(192, 150, 46);
}

@media (max-width: 768px) {

    .banner .heading {
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 2rem;
    }

    .banner .quote {
        font-size: 1.3rem;
        text-align: center;
    }

    .about-us-row {
        flex-direction: column;
    }

    .about-us-image {
        width: 100%;
    }

    .about-us-image img {
        display: block;
        margin: 0 auto;
    }

    .about-us-text {
        margin-top: 1.2rem;
        width: 100%;
        font-size: 1.2rem;
    }


    .why-us-row {
        flex-direction: column;
    }

    .why-us-row:nth-child(2) {
        flex-direction: column;
    }

    .why-us-text {
        width: 100%;
    }

    .card-row {
        flex-direction: column;
    }

    .card-container {
        width: 100%;
        margin: 1.6rem 0;
    }
}