.card-parent {
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: row; */
  flex-wrap: wrap;
  min-height: 40vh;
}

.card-style-item {
  width: 250px;
  min-height: 200px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin: 18px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-style-item img {
  width: 100%;
  height: 200px;
  margin: 1.3rem 0;
  border-radius: 15px;
  object-fit: cover;
}

.card-style-item:hover {
  box-shadow: 0px 12px 16px 24px rgba(0, 0, 0, 0.03);
}

.card-style-item h2 {
  color: #8B4513;
}

.card-style-item p {
  font-weight: bold;
  margin-bottom: 6px;
  color: #8B4513;
}

.button-style {
  /* background: #8B4513; */
  background-image: linear-gradient(#8B4513, #b65b1a);
  color: #fff;
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  cursor: pointer;
}

;