
.error-container {
    text-align: center;
    padding: 20px;
    background-color: #d1cece;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: rgb(223, 166, 20);
    height: 100vh;  
  }
  
  .error-content {
    margin-top: 20vh;
  }

  .error-content h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .error-content p {
    font-size: 20px;
    color: #262525;
    margin: 20px 0;
  }
  
  .error-button {
    background-color:  rgb(223, 166, 20);
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 2rem;
  }