* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #ccc; */
}

body {
  background-color: rgb(237, 234, 234);
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.section-heading {
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  color: #8B4513;
}

.container {
  width: 85%;
  display: block;
  margin: 4.5rem auto;
  /* border: 2px solid red; */
}

.no-data {
  color: #8B4513;
  text-align: center;
  height: 45vh;
}

.no-data p {
  font-size: 2rem;
  margin: 1.5rem 0;
}

.panel-container {
  display: flex;
  height: 100vh;
}

.panel-content {
  padding: 10px;
  max-width: 80%;
}

.table-container {
    overflow-y: scroll;
    margin: 2rem 0 0 1rem;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}


/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 5% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.table-image {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.create-btn {
  cursor: pointer;
  background-color: #8B4513;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
  outline: none;
  border: none;
  margin: 1.5rem;
}

.delete-btn {
  cursor: pointer;
  background-color: #8B4513;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.form-grp input {
  padding: 8px 12px;
  border-radius: 0.5rem;
  border: 1px solid rgb(206, 198, 198);
  outline: none;
}

.form-grp select {
  padding:8px 12px;
  border-radius: 0.5rem;
  border: 1px solid rgb(206, 198, 198);
  outline: none;
}

@media (max-width: 768px) {

  .container{
    width: 90%;
  }
}