.card-parent{
    display: flex;
    justify-content: space-evenly;
    /* flex-direction: row; */
    flex-wrap: wrap;
    min-height: 45vh;
}

.card-style-dashboard {
    width: 200px;
    height: 150px; 
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  
.card-style-dashboard:hover {
    transform: scale(1.1);
    transition: 0.8s all;
}
  .card-style-dashboard h3 {
    color: #8B4513;
  }

  .count-var {
    font-size: 1.6rem;
    color: gray;
  }