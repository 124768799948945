.footer {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0 2rem 2rem;
    /* background-color: #8B4513; */
    background-image: linear-gradient( #8B4513, #b65b1a);
    color: white;
    flex-wrap: wrap;
}

.company-name {
    color: goldenrod !important;
}

/* .footer-section {
    width: calc(25% - 1rem);
    margin: 0.5rem 0;
} */

.footer-section h6 {
    color: white;
    margin-bottom: 15px;
    font-size: 1.3rem;
}

.footer-section p {
    margin: 0.5rem 0;
}

.footer-section .footer-links {
    color: white;
    text-decoration: none;
}


.footer-section .footer-links:hover {
    text-decoration: underline;
    transition: 0.4s ease-out;
    color: goldenrod;
}

.copyright {
    text-align: center;
    padding-bottom: 2rem;
    /* background-color: #8B4513; */
    background-color: #b65b1a;
    color: white;
}

.footer-section-1 {
    width: 30%;
}

.footer-section-2 {
    width: 20%;

}

.footer-section-3 {
    width: 20%;
}

.footer-section-4 {
    width: 20%;
}


@media (max-width: 768px) {

    .footer {
        flex-direction: column;
        text-align: center;
    }

    .footer-section-1 {
        width: 90%;
        margin-bottom: 1rem;
    }
    
    .footer-section-2 {
        width: 90%;
        margin-bottom: 1rem;
    }
    
    .footer-section-3 {
        width: 90%;
        margin-bottom: 1rem;
    }
    
    .footer-section-4 {
        width: 90%;
        margin-bottom: 1rem;
    }
}