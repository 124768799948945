.contact-heading {
    font-size: 1.5rem;
    margin: 2rem 0;
    text-align: center;
    color: #696565;
}

.contact-box {
    /* border: 2px solid red; */
    display: flex;
}

.contact-info {
    width: 50%;
    /* border: 2px solid blue; */
}

.contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3.5rem;
    font-size: 1.3rem;
    color: #8B4513;
}

.container-contact {
    width: 85%;
    display: block;
    margin: 4.5rem auto;
    /* border: 2px solid red; */
}

.info {
    margin: 1.3rem 0;
    /* text-align: center; */
}

.info i {
    margin-right: 1rem;
}


.contact-form {
    width: 50%;
}

.contact-subheading {
    text-align: center;
    font-size: 2rem;
    color: #8B4513;
    font-weight: 700;
}

.social-links a {
    color: #8B4513;
    text-decoration: none;
}

.social-links i{
    margin: 1rem 1.5rem 1rem 0;
    font-size: 1.8rem;
}

.form-body {
    display: block;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 2rem 1rem;
    border-radius: 12px;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.03);
}

.form-body:hover {
    box-shadow: 0px 12px 16px 14px rgba(0, 0, 0, 0.03);
}

.form-grp {
    display: flex;
    flex-direction: column;
    margin: 1.3rem;
}

.form-grp label {
    margin-bottom: 0.5rem;
}

.form-grp input {
    padding: 8px 12px;
    border-radius: 0.5rem;
    border: 1px solid rgb(206, 198, 198);
    outline: none;
}

textarea {
    border-radius: 0.5rem;
    border: 1px solid rgb(206, 198, 198);
    outline: none;
}

.submit-btn {
    /* background-color: #8B4513; */
    background-image: linear-gradient( #8B4513, #b65b1a);
    color: #ffffff;
    padding: 0.4rem;
    border-radius: 16px;
    border: none;
    outline: none;
    font-size: 1rem;
    height: 2.3rem;
    cursor: pointer;
}

.success-msg{
    /* border: 2px solid red; */
    width: 95%;
    border-radius: 15px;
    display: block;
    margin: 0 auto;
    padding: 8px 10px;
    color: #ffffff;
    background-color: rgb(43, 175, 43);
}

.error-msg {
    width: 95%;
    border-radius: 15px;
    display: block;
    margin: 0 auto;
    padding: 8px 10px;
    color: #ffffff;
    background-color:rgb(206, 19, 19);
}

.google-map-code {
    width: 90%;
    height: 500px;
    display: block;
    margin: 2rem auto;
    border-radius: 20px;
    border: none;
    outline: none;
}

@media (max-width: 768px) {

    .contact-heading {
        font-size: 1.2rem;
        padding: 0 6px;
    }

    .container-contact {
        width: 95%;
    }

    .contact-info {
        width: 100%;
    }
    .contact-form {
        margin-top: 1rem;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .contact-box {
        flex-direction: column;   
    }
    .contact-details {
        margin-left: 0.8rem;
        font-size: 1.2rem;
    }

    .google-map-code {
        width: 95%;
        height: 350px;
    }
    
    .info i {
        margin-right: 0;
        display: block;
        margin-bottom: 0.4rem;
    }
}