.auth-page {
    /* border: 1px solid #8B4513; */
    background-color: white;
    min-height: 50vh;
    width: 50%;
    display: block;
    margin: 3rem auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.auth-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.auth-tab {
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #8B4513;
    border-radius: 5px 5px 0 0;
    /* background-color: #8B4513; */
    background-image: linear-gradient( #8B4513, #b65b1a);
    color: white;
    font-weight: bold;
    flex: 1;
    text-align: center;
    transition: background-color 0.4s ease-in-out;
}

.auth-tab.active {
    background-image: none;
    background-color: white;
    color: #8B4513;
}

.auth-form {
    display: flex;
    flex-direction: column;
}

.auth-form div {
    margin-bottom: 10px;
}

.auth-form label {
    font-weight: bold;
    margin: 8px 0;
}

.auth-input {
    width: 100% !important;
    padding: 10px !important;
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
}

.auth-button {
    padding: 12px 4px;
    margin-top: 8px;
    /* background-color: #8B4513; */
    background-image: linear-gradient( #8B4513, #b65b1a);
    outline: none;
    color: #ffffff;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 16px;
    margin-top: 1rem;
}

.user-greet {
    display: flex;
    justify-content: space-between;
    background-color: #8B4513;
    padding: 1rem 0.4rem;
    color: #ffffff;
    font-size: 20px;

}

.user-greet p {
    margin-left: 10px;
}

.log-out-btn {
    padding: 4px 8px;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: goldenrod;
    color: #ffffff;
    border: 1px solid goldenrod;
}

.single-item-container {
    display: flex;
    background-color: #ffffff;
}

.single-item-image {
    width: 50%;
}

.single-item-image img {
    height: 300px;
    width: 300px;
    object-fit: cover;
    display: block;
    margin: 1rem auto;
}

.single-item-details {
    width: 50%;
}

.single-item-details p {
    display: block;
    margin: 1rem auto 1.5rem;
    font-size: 20px;
}

.purchase-btn {
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    background-color: #8B4513;
    color: #ffffff;
    border-radius: 20px;
    outline: none;
    margin-bottom: 1rem;
}

@media (max-width: 1200px) {

    .auth-page {
        width: 70%;
    }
}

@media (max-width: 768px) {

    .auth-page {
        width: 80%;
    }

    .single-item-container  {
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
    }

    .single-item-details{
        width: 100%;
        padding: 0 25px;
    }
    .single-item-image {
        width: 100%;
    }
   
}

@media (max-width: 500px) {

    .auth-page {
        width: 90%;
    }
}


@media (max-width: 360px) {

    .single-item-image img {
        height: 250px;
        width: 250px;
    }
    
}

