* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1.5rem;
  /* background-color: rgb(237, 234, 234); */
  background-image: linear-gradient( #8B4513, #b65b1a);
  color: #fff;
  font-size: 1.5rem;
  box-shadow: rgb(220, 220, 220) 0px 2px 10px;
}

/* .logo {
  font-size: 1.5rem;
} */

.img-logo {
  height: 45px;
  width: 130px;
  object-fit: contain;
  cursor: pointer;
  /* border-radius: 50%; */
}

.links .anchor {
  color: #fff;
  text-decoration: none;
  margin: 0 20px;
}

.links .anchor:hover {
  color: goldenrod;
  text-decoration: none;
  margin: 0 20px;
  transition: 0.4s ease-in;
}


.social-icons {
  display: flex;
  gap: 20px;
  margin-left: 2%;
}

.social-icons i {
  cursor: pointer;
  color: #ffffff;
}

.hamburger {
  display: none;
  /* Hide by default on larger screens */
}

/* Media Query for Mobile View */
@media (max-width: 768px) {

  .navbar {
    padding: 1rem;
    /* font-size: 1.3rem; */
  }


  .links {
    display: none;
    /* Hide links in mobile view */
  }

  .social-icons {
    display: none;
    /* Hide social icons in mobile view */
  }



  /* Styles for the hamburger menu */
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .bar {
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    margin: 2px 0;
    transition: 0.3s ease-in-out;
  }

  /* Styles for the active state */
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .links.active {
    /* Your styles for links when menu is open */
    display: flex;
    background-color: #b65b1a;
    /* background-image: linear-gradient( #8B4513, #b65b1a); */
    color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4rem;
    right: 0;
    width: 100%;
    padding: 10px;
  }

  .links.links.active .anchor{
    margin: 2rem 0;
  }

}