.card-parent{
    display: flex;
    justify-content: space-evenly;
    /* flex-direction: row; */
    flex-wrap: wrap;
    min-height: 40vh;
}

.card-style {
    width: 250px;
    height: 200px; 
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 20px;
    margin: 18px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

.card-style h2{
    color: #8B4513;
}

.card-style p {
  color: #000000;
}

.button-style  {
    /* background: #8B4513; */
    background-image: linear-gradient( #8B4513, #b65b1a);
    color: #fff;
    border: none;
    border-radius: 16px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
    cursor: pointer;
  };