/* AdminLogin.css */

/* Create a container to center the form vertically and horizontally */
.page-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* 100% of the viewport height */
  }
  
  /* Container for the login form */
  .login-container {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Center the login form */
  .login-container h2 {
    text-align: center;
    color: #333;
  }
  
  /* Style the form fields and button */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    color: #555;
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
.admin-login-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
.admin-login-btn:hover {
    background-color: #0056b3;
  }
  